.skill-item {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  margin-top: 2rem;
  width: 33%;
}

.other-skills-container h2 {
  text-decoration: underline;
}

.other-skills-container {
  display: flex;
  flex-direction: column;
  border-radius: 5%;
  padding-bottom: 1rem;
}

.card-title {
  padding: 0 0.5rem 0.25rem 0;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .other-skills-container {
    margin: 0;
    width: 130vw;
    position: relative;
    left: 1rem;
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 1400px) {
  .other-skills-container {
    padding-bottom: 2rem;
  }
}
