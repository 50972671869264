.portfolio-container-wrapper {
  padding: 2rem 3rem;
}

.portfolio-container-wrapper h2 {
  padding-bottom: 2rem;
  text-decoration: underline;
}

.items-container {
  display: grid;
  gap: 1rem 3rem;
}

.items-container div {
  width: 42vw;
}

.items-container img {
  height: 15rem;
  width: 20rem;
  cursor: pointer;
  border-radius: 3%;
  margin-top: 0.5rem;
}

.pixels-comp {
  grid-column: 1;
  grid-row: 2;
}

.todo-comp {
  grid-column: 2;
  grid-row: 1;
}

.currency-comp {
  grid-column: 1;
  grid-row: 1;
}

.status-comp {
  grid-column: 2;
  grid-row: 2;
}

.hackfraud {
  position: relative;
  bottom: 1.5rem;
}

@media (max-width: 600px) {
  .items-container {
    gap: 1rem;
  }
}

@media (min-width: 1400px) {
  .items-container {
    gap: 1rem;
  }

  .items-container div {
    width: 42vw;
  }
}
