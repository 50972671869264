.contact-container {
  background-color: #999999;
  border-radius: 5%;
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: 5px 5px 5px rgb(0, 0, 0);
  width: 65vw;
}

.contact-container h2 {
  margin-bottom: 1rem;
  color: black;
}

.namefield,
.messagefield,
.emailfield {
  border: solid black 2px;
  background-color: rgb(245, 245, 245);
}

.messagefield {
  margin: 1rem;
  height: 10rem;
  width: 75%;
}

.namefield,
.emailfield {
  width: 35%;
  margin: 0 1rem;
}

.submit-btn,
.submit-btn:hover {
  border: black solid 1px;
}

.btn:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

@media (max-width: 600px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    width: 130%;
    margin-top: 1.5rem;
    position: relative;
    left: 2rem;
  }

  .messagefield {
    margin: 1rem;
    height: 10rem;
    width: 75%;
  }

  .namefield,
  .emailfield {
    width: 40%;
  }
}

@media (min-width: 1400px) {
  .messagefield {
    margin: 1rem;
    height: 10rem;
    width: 75%;
  }

  .namefield,
  .emailfield {
    width: 40%;
  }
}
