.head {
  font-size: 1.5rem;
  margin-bottom: 3rem;
  width: 85vw;
}

.parent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.header-namefield {
  margin: none;
  width: max-content;
}

.dropdown {
  margin: none;
  font-size: 1.25rem;
}

@media (max-width: 600px) {
  .parent {
    display: grid;
    width: 90vw;
    grid-gap: 10rem;
    align-items: baseline;
  }

  .header-namefield {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .dropdown {
    grid-column-start: 3;
    grid-column-end: 4;
    padding-right: 2rem;
    position: relative;
    left: 5rem;
  }
}
