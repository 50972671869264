.about-wrapper {
  display: grid;
  background-color: rgb(106, 106, 106);
  border-radius: 5%;
  padding: 1rem 2rem 1rem 1rem;
  margin-bottom: 2rem;
  box-shadow: 10px 5px 5px rgb(30, 30, 30);
  width: 65vw;
}

.aboutme {
  padding-bottom: 1rem;
  text-decoration: underline;
}

.pics-wrapper {
  grid-column: 1;
  grid-row: 1;
  background-color: rgba(193, 193, 193, 0.376);
  border-radius: 5%;
  padding: 3rem 1rem 3rem 1rem;
  margin: 1rem 1rem 1rem 0rem;
}

.summary {
  grid-column: 2;
  grid-row: 1;
  width: 90%;
  margin: auto;
  padding: 1rem 0;
}

.headshot {
  margin: 1rem;
  height: 10rem;
  border-radius: 5%;
}

.coding-pics img {
  height: 3rem;
  margin: 1rem 0.25rem;
}

.js,
.react {
  margin-left: 6px;
}

@media (max-width: 600px) {
  .about-wrapper {
    margin: 0 5rem 2rem 0;
    padding-bottom: 2rem;
  }
  .summary {
    width: 80%;
    margin: 0;
  }
  .headshot {
    margin-top: 1rem;
    height: 10rem;
  }
  .aboutme {
    padding-bottom: 2rem;
    position: relative;
    right: 1rem;
  }
}

@media (min-width: 1400px) {
  .about-wrapper {
    border-radius: 5%;
    width: 55vw;
  }
  .summary {
    padding: 10% 0;
    position: relative;
    left: 1rem;
  }
}
