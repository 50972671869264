.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 600px) {
  .flex-container {
    width: 90vw;
    align-items: flex-start;
  }
}
