.base-app {
  background-color: rgb(211, 211, 211);
  border-radius: 1%;
  padding: 1rem 0;
}

.RS-header {
  font-size: 2rem;
  margin-left: 1rem;
}

.base-app hr {
  width: 1px;
}

.portfolio-container img {
  width: fit-content;
  margin-bottom: 1rem;
}

.likebar {
  font-size: 0.9rem;
}

.buttonLink {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0 0 0 0;
  position: relative;
  top: 1rem;
  right: 0.5rem;
}

.addnew-container {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding-bottom: 1.5rem;
}

.addnew-container p {
  margin: auto;
  margin-bottom: 1rem;
}

.textarea {
  margin: auto;
  margin-bottom: 1.5rem;
  font-size: 75%;
  width: 75%;
}

.newstatus-btn {
  border-radius: 25%;
  font-size: 1rem;
  box-shadow: 0.15rem 0.15rem grey;
  width: 5rem;
  margin: auto;
}

.newstatus-btn:hover {
  background-color: rgb(107, 170, 225);
}

.newstatus-btn:active {
  background-color: rgb(74, 115, 151);
}

.statusbox {
  font-size: 1.25rem;
  border: black 2px solid;
  margin: 2rem;
  padding: 1rem 2rem;
  display: grid;
}

.statustext {
  justify-self: right;
  grid-column: 1/3;
  grid-row: 1;
  width: 100%;
}

.likebar {
  grid-column: 1/2;
  grid-row: 2;
}

.hide {
  grid-column: 2/3;
  grid-row: 2;
  font-size: 75%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}
