html {
  font-family: sans-serif;
  background-color: rgb(72, 72, 72);
  color: white;
  padding-bottom: 3rem;
  height: 100vh;
  width: 90vw;
  margin: auto;
}

body {
  padding: 0 2rem;
  background-color: rgb(72, 72, 72) !important;
}

body a,
.a-style {
  color: rgb(142, 226, 136);
}

body hr {
  border-top: rgb(192, 192, 192) solid 2px;
  width: 80vw;
  padding-bottom: 1rem;
}

@media (max-width: 600px) {
  html {
    width: 80vw;
    padding-right: 2rem;
  }
  body hr {
    border-top: rgb(192, 192, 192) solid 2px;
    width: 150%;
  }
}

@media (min-width: 1400px) {
  html {
    font-size: 1.25rem;
  }

  body a {
    font-size: 1.25rem;
  }
}
